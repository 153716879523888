<template>
  <div class="VerificationCode">
    <div class="VerificationCode_head">
      <p>智能随访机器人大数据平台</p>
      <p>|</p>
      <p>忘记密码</p>
    </div>
    <div class="VerificationCode_body">
      <div class="VerificationCode_body_head">
        <p>找回密码</p>
        <p @click="loginPage">返回登录></p>
      </div>
      <div class="VerificationCode_body_middle">
        <div class="page">
          <div class="steps">
            <div
              v-for="(item,index) in process"
              class="step step_r"
              :class="{active:index==i?true:false}"
              :key="index"
            >
              <div style="position:absolute;right:-42px;height:60px;width:80px;overflow:hidden;">
                <div class="rotateBox"></div>
              </div>
              <div class="step_b">
                <div class="num">{{index+1}}</div>
                <div class="info">{{item.step}}</div>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
        <div class="form" v-show="i==0">
          <el-form ref="form" label-width="100px">
            <el-form-item label="账号:">
              <el-input v-model="name" style="width:320px;"></el-input>
            </el-form-item>
            <el-form-item label="验证码:">
              <el-input v-model="name" style="width:200px;margin-right:8px;"></el-input>
              <el-button v-show="show" @click="getCode">获取验证码</el-button>
              <el-button v-show="!show" class="count">{{count}} s</el-button>
            </el-form-item>
            <el-form-item>
              <el-button style="width:320px;" type="primary" @click="nextstep">下一步</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="form"  v-show="i==1">
          <el-form :model="ruleForm"  :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="密码" prop="pass">
              <el-input show-password style="width:320px;" type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input show-password style="width:320px;" type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button style="width:320px;" type="primary" @click="submitForm('ruleForm')">下一步</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="form"  v-show="i==2">
          <div class="form_name">
<!--            <img src="../assets/image/wanchen.png">-->
            <p>您的密码修改成功!</p>
            <el-button style="width:320px;" type="primary" @click="onSubmit">返回登录</el-button>
          </div>
        </div>
      </div>
      <div class="VerificationCode_body_bottom">
        <p>Copyright © 2016-2021 SJS TECH. All Rights Reserved.</p>
        <p>鄂ICP备15019034号-4</p>
        <p>武汉赛捷思科技有限公司 版权所有</p>
      </div>
    </div>
  </div>


</template>
<script>
export default {
  data(){
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      i:0,
      name:"",
      show: true,
      count: '',
      timer: null,
      process:[{step:"填写信息"},{step:"确认新密码"},{step:"修改完成"}],
      ruleForm: {
        pass: '',
        checkPass: '',
      },
      rules: {
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      }

    }
  },

  methods:{
    nextstep(){
      this.i=1
    },
    loginPage() {
      this.$router.push('/')
    },
    submitForm(formName) {
      this.i=2
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getCode(){//验证码
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
    //返回登陆
    onSubmit(){
      this.$router.push('/')
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  }
}
</script>
<style lang='scss' scoped>
.VerificationCode{
  width: 100%;
  height: 100%;
  background: #F0F7FD;
  .VerificationCode_head{
    width: 100%;
    height: 80px;
    display: flex;
    align-items:center;
    background: #fff;
    box-shadow: 0 3px 10px rgba(0, 104, 163, 0.1);
    margin-bottom: 20px;
    p:nth-child(1){
      color: #333333;
      font-size: 26px;
      margin-left: 35px;
      margin-right: 30px;
    }
    p:nth-child(2){
      width: 0;
      height: 24px;
      margin-right: 20px;
    }
    p:nth-child(3){
      color: #666;
      font-size: 20px;
    }
  }
  .VerificationCode_body{
    height: 89%;
    background: #fff;
    .VerificationCode_body_head{
      height: 50px;
      border-bottom: 1px solid #dfdfdf;
      display: flex;
      align-items:center;
      justify-content:center;
      background: #fff;
      p:nth-child(1){
        color: #333333;
        font-size: 20px;
        margin-right: 200px;
      }
      p:nth-child(2){
        font-size: 16px;
        color: #3388FF;
        margin-left: 200px;
      }
    }
    .VerificationCode_body_middle{
      width: 900px;
      margin: 0 auto;
      margin-bottom: 50px;
      .form{
        width: 500px;
        height: 450px;
        margin: 0 auto;
        .form_name{
          text-align: center;
          img{
            margin-bottom: 30px;
          }
          p{
            font-size: 20px;
            color: #585858;
            margin-bottom: 30px;
          }
        }
      }
    }
    .VerificationCode_body_bottom{
      width: 900px;
      margin: 0 auto;
      text-align: center;
      color: #AAAFB5;
      p{
        margin-bottom: 6px;
        font-size: 14px;
      }
    }
  }

}
.page {
  height: 60px;
  width: 100%;
  margin-bottom: 60px;
  .steps {
    width: 100%;
    display: flex;
    margin-top: 17px;
    zoom: 1;
  }
  .steps::after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0;
    line-height: 0;
  }
  .step {
    flex: 1;
    width: 320px;
    height: 60px;
    background: #CCE7FF;
    position: relative;
    .step_b {
      width: 230px;
      margin: 18px auto 7px 70px;
      display: flex;
      .num {
        width: 30px;
        font-size: 18px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        color: #fff;
        background: #1890FF;
      }
      .info{
        line-height: 30px;
        margin-left: 10px;
        color: #3388FF;
      }
    }
  }
  //旋转的小方形盒子
  .rotateBox {
    content: "";
    border:10px solid #fff;
    border-bottom: none;
    border-left: none;
    background: #CCE7FF;
    height: 38px;
    width: 46px;
    position: absolute;
    display: block;
    top: 6px;
    right: 15px;
    z-index: 10;
    transform:rotate(45deg);
  }
  .step_l {
    margin-right: 0;
  }
  .active {
    background: #3388FF !important;
    .num {
      background: #fff !important;
      color: #3388FF !important;
    }
    .info {

      color: #FFFFFF !important;
    }
  }
  //被激活的步骤条的样式
  .active .rotateBox {
    // background-color: #75B0DF;
    background: #3388FF;
    content: "";
    border: 10px solid #fff;
    border-bottom: none;
    border-left: none;
    height: 38px;
    width: 46px;
    position: absolute;
    display: block;
    top: 6px;
    right: 15px;
    z-index: 10;
    transform:rotate(45deg);
    // -ms-transform:rotate(45deg);  /* IE 9 */
    // -moz-transform:rotate(45deg);   /* Firefox */
    // -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
    // -o-transform:rotate(45deg);   /* Opera */
  }
  //最后一步取消向右箭头
  .step:last-child .rotateBox{
    content: "";
    border: none;
    height: 0;
    width: 0;
    z-index: 11;
  }
}

</style>
